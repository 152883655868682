import { useTranslation } from "react-i18next";
import CustomModal from "../CustomModal/CustomModal";
import "./SupportReportPopup.scss";
import {
  AssessmentButton,
  AttachmentIcon,
  InputField,
  showToast,
  TextEditor,
  TypeSelect,
} from "ui-components";
import { useEffect, useState } from "react";
import {
  calculateMinusAmount,
  generateUUID,
} from "../../utils/Common/Constant";
import { ImageUploader } from "../../utils/Common/MediaUploader";
import {
  createSupportTicket,
  getAllSupportType,
} from "../../service/SubmitAssessment/SubmitAssessment";

interface SupportReportPopupProps {
  show: boolean;
  title?: string;
  setIsPopupOpen?: any;
}
const SupportReportPopup = ({
  show,
  title,
  setIsPopupOpen,
}: SupportReportPopupProps) => {
  const { t } = useTranslation();
  const applicantId = localStorage.getItem("applicantId") ?? "";
  const textEditorLimit = 1001;
  const [isSendEnable, setSendEnable] = useState(false);
  const [supportType, setSupportType] = useState("");
  const [description, setDescription] = useState("");
  const [countManageFlag, setCountManageFlag] = useState(false);
  const [attachmentImage, setAttachmentImage] = useState<any>(null);
  const [supportOptions, setSupportOptions] = useState([]);
  const [countTextInEditor, setCountTextInEditor] =
    useState<number>(textEditorLimit);
  const [titleText, setTitleText] = useState<any>("");
  const [bugTypeId, setBugTypeId] = useState([]);
  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setSupportType("");
    setDescription("");
    setTitleText("");
    setAttachmentImage(null);
  };
  // Function to check if the text editor content is valid (not empty or just a placeholder)
  const isTextareaValid = () => {
    return description.trim() !== "" && description.trim() !== "<p><br></p>";
  };
  const textEditorHandleChange = (
    content: any,
    _delta: any,
    _source: any,
    editor: any,
  ) => {
    const currentLength = editor.getLength();
    setCountTextInEditor(currentLength);
    setCountManageFlag(true);
    setDescription(content);
  };
  useEffect(() => {
    if (title === "support") {
      if (supportType.trim() && isTextareaValid()) {
        setSendEnable(true);
      } else {
        setSendEnable(false);
      }
    } else {
      if (titleText.trim() && isTextareaValid()) {
        setSendEnable(true);
      } else {
        setSendEnable(false);
      }
    }
  }, [supportType, description, titleText]);
  //function to Create support ticket
  const createSupportTicketApi = async () => {
    try {
      setSendEnable(false);
      const res = await createSupportTicket({
        supportTicketTypeId: title === "report" ? bugTypeId : supportType,
        userId: applicantId,
        assignId: null,
        userType: "APPLICANT",
        status: "NEW",
        subject: title === "report" ? titleText : "Support",
        description: description,
        attachmentUrls: attachmentImage && [attachmentImage?.ImageUrl],
      });
      showToast(res?.customMessage, "success");
    } catch (error) {
      console.error(error);
    } finally {
      handleClosePopup();
      setSupportType("");
      setDescription("");
      setAttachmentImage(null);
      setSendEnable(true);
    }
  };
  const handleSubmit = () => {
    createSupportTicketApi();
  };
  useEffect(() => {
    getAllSupportTypeApi();
  }, []);
  // get support types
  const getAllSupportTypeApi = async () => {
    try {
      const supportTye = await getAllSupportType();
      if (supportTye?.data?.status === 200) {
        const updateData = (supportTye?.data?.data?.data || [])
          .map((item: any) => ({
            value: item?.supportTicketTypeId,
            label: item?.supportTicketTypeName,
          }))
          .filter((item: any) => item.label !== "Bug"); //excluded bug from support types
        setSupportOptions(updateData);
        // only take id of bug type
        const reportBugObj = (supportTye?.data?.data?.data || [])
          .map((item: any) => ({
            value: item?.supportTicketTypeId,
            label: item?.supportTicketTypeName,
          }))
          .filter((item: any) => item.label === "Bug");
        setBugTypeId(reportBugObj[0]?.value);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <CustomModal
      show={show}
      isCrossBtn={true}
      isHeaderVisible={false}
      handleClose={handleClosePopup}
      className="supportReportPopup"
      size="lg"
      fullscreen="md-down"
      isFooterVisible={false}
    >
      <div className="supportReportContent">
        <div className="supportHeader">
          <h2 className="supportTitle">
            {title === "report" ? t("REPORT_BUG") : t("GENERAL.HOW_CAN_HELP")}
          </h2>
        </div>
        <div className="supportForm">
          {title === "support" ? (
            <div className="formGroup">
              <label htmlFor="supportType">{t("GENERAL.SUPPORT_TYPE")}</label>
              <TypeSelect
                value={supportType}
                onChange={(e: string) => setSupportType(e)}
                options={supportOptions}
                loadingText={t("GENERAL.LOADING")}
                placeholder="Please Select"
                className={supportType && "selectedType"}
              />
            </div>
          ) : (
            <div className="formGroup">
              <label htmlFor="supportType">{t("GENERAL.TITLE")}</label>
              <InputField
                value={titleText}
                additionalProps={{
                  className: "textInput",
                }}
                onChange={(e) => setTitleText(e)}
              />
            </div>
          )}
          <div className="formGroup">
            <label htmlFor="message">{t("GENERAL.DESCRIPTION")}</label>
            <TextEditor
              value={description}
              onChange={textEditorHandleChange}
              placeholder=""
            />
            <div className="textEditorMsg">
              <p className="errorMessage">
                {countTextInEditor > textEditorLimit && (
                  <>
                    {t("GENERAL.MUST_BE_LESS_THAN")} {textEditorLimit - 1}{" "}
                    {t("GENERAL.CHARACTERS")}
                  </>
                )}
              </p>
              {(countManageFlag ? 1 : textEditorLimit) != countTextInEditor && (
                <p className="limitMsg">
                  {calculateMinusAmount(countTextInEditor, textEditorLimit)}{" "}
                  {t("CREATE_ASSESSMENT.TEXT_EDITOR_LIMIT")}
                </p>
              )}
            </div>
          </div>
          <div className="attachmentSection">
            <div className="attachedImgSec">
              <input
                className="attachedInput"
                type="file"
                accept="image/*"
                onChange={async (e: any) => {
                  const ImageData = await ImageUploader(
                    e,
                    `${applicantId}/${title}/${generateUUID()}`,
                  );
                  setAttachmentImage(ImageData);
                }}
              />
              <div className="attachedIconSec">
                <p>{t("GENERAL.ATTACH_IMAGE")}</p>
                <AttachmentIcon />
              </div>
            </div>
            {attachmentImage?.ImageUrl && (
              <div className="attachedImageParent">
                <button
                  className="attachCrossBtn"
                  onClick={() => setAttachmentImage(null)}
                >
                  X
                </button>
                <div className="questionImageAttParent">
                  <img
                    className="attachedImageQuestion"
                    src={
                      (attachmentImage?.ImageUrl as any) instanceof Blob
                        ? URL.createObjectURL(attachmentImage?.ImageUrl as any)
                        : attachmentImage?.ImageUrl
                    }
                    alt="Attachment"
                  />
                </div>
              </div>
            )}
          </div>

          <AssessmentButton
            type="submit"
            className="submitButton"
            disabled={!isSendEnable}
            onClick={handleSubmit}
          >
            {t("GENERAL.SEND")}
          </AssessmentButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default SupportReportPopup;
