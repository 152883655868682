import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AssessmentButton, InputField, showToast } from "ui-components";
import { loginUserData } from "../../../utils/Common/Constant";
import * as Yup from "yup";
import ApplicantSignInWrapper from "../../../components/ApplicantSignInWrapper/ApplicantSignInWrapper";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
import { forgotpasswordApi } from "../../../service/SubmitAssessment/SubmitAssessment";
const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  // login User Schema
  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });
  //call to get link after enter email
  const handleForgotPassword = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      email: value?.email,
      type: "Applicant",
    };
    try {
      const res = await forgotpasswordApi(data);
      setSubmitting && setSubmitting(true);
      if (res?.status === 200) {
        showToast(res?.customMessage, "success");
        navigate("/", { replace: true });
        resetForm && resetForm();
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting && setSubmitting(false);
    }
  };
  return (
    <ApplicantSignInWrapper>
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          resetForm && resetForm();
          setSubmitting && setSubmitting(true);
          handleForgotPassword(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <div className="userContent">
                <h1 className="userHeadingApp">
                  {t("GENERAL.RESET_YOUR_PASS")}
                </h1>
                <div className="mt-5">
                  <Field name="email">
                    {() => (
                      <InputField
                        placeholder={`${t("GENERAL.EMAIL_ADDRESS")} *`}
                        onChange={(newValue: any) =>
                          setFieldValue("email", newValue.trim())
                        }
                        value={values.email}
                        additionalProps={{ className: "emailApplicantLogin" }}
                      />
                    )}
                  </Field>
                  {errors?.email && touched?.email ? (
                    <div className="errorMessage">{errors?.email}</div>
                  ) : null}
                </div>
                <AssessmentButton
                  className="submitBtn"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? t("GENERAL.LOADING") : t("GENERAL.GET_LINK")}
                </AssessmentButton>
                <div className="alreadyHaveAccout">
                  <span>
                    {t("GENERAL.ALREADY_HAVE_ACC")}{" "}
                    <span
                      className="signIn-text"
                      onClick={() =>
                        navigate(`/${currentLanguage}/applicant-login`, {
                          replace: true,
                        })
                      }
                    >
                      {t("GENERAL.SIGN_IN")}
                    </span>
                  </span>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </ApplicantSignInWrapper>
  );
};
export default ForgotPassword;
