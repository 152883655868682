import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

// Applicant flow (submit)
export function PublicApplicantRoute({ children }: any) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const token = localStorage.getItem("applicantToken");
  const isAuthenticated = Boolean(token);
  return isAuthenticated ? (
    <Navigate to={`/${currentLanguage}/submitassessment`} />
  ) : (
    <>{children}</>
  );
}

// Applicant flow (submit)
export function PrivateApplicantRoute({ children }: any) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const token = localStorage.getItem("applicantToken");
  const isAuthenticated = Boolean(token);
  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={`/${currentLanguage}/applicant-login`} />
  );
}
