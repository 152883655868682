import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import CircleCrossIcon from "../../../utils/Images/CircleCrossIcon";
import "./CompleteProfile.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { removeSessionStorageItem } from "../../../utils/SessionStorageMethod/removeSessionStorageItem";
interface CompleteProfile {
  handleProfileSubmit: (values: any) => void;
  handleProfileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  profileUpdate: any;
}
const CompleteProfile: React.FC<CompleteProfile> = ({
  handleProfileSubmit,
  handleProfileChange,
  profileUpdate,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const completeProfileSchema = Yup.object({
    password: Yup.string()
      .required(t("ERROR.ENTER_NEW_PASSWORD"))
      .min(6, t("ERROR.PASSWORD_MIN_SIX_CHAR"))
      .max(32, t("ERROR.PASSWORD_MAX_THIRTYTWO_CHAR"))
      .matches(/[0-9]/, t("ERROR.ATLEAST_ONE_DIGIT"))
      .matches(/[a-z]/, t("ERROR.ATLEAST_ONE_LOWERCASE"))
      .matches(/[A-Z]/, t("ERROR.ATLEAST_ONE_UPPERCASE"))
      .matches(
        /(?=.*?[#?!~@$%^&*-:;"><,./?_{}()-`=+])/,
        t("ERROR.ATLEAST_ONE_SPECIAL_CHARACTERS"),
      )
      .test(
        t("ERROR.NO_SPACES"),
        t("ERROR.PASSWORD_WITH_NO_SPACES"),
        (value) => value === undefined || !/\s/.test(value),
      )
      .test(
        t("ERROR.CONDITION_VALIDATION"),
        t("ERROR.NO_TRAILING_SPACES"),
        (value) => value === undefined || value.trim() === value,
      ),
  });
  return (
    <div className="userSec">
      <div className="formContent">
        <div className="completeProfileCrossIcon">
          <button
            onClick={() => {
              navigate(`/${currentLanguage}/applicant-login`);
              removeSessionStorageItem("completeProfileIsuserSubmit");
            }}
          >
            <CircleCrossIcon />
          </button>
        </div>
        <h3 className="completeProfileTitle">
          {t("SUBMIT_ASSESSMENT_COMPLETE_PROFILE")}
        </h3>
        <div className="inputContent">
          <Formik
            initialValues={{
              password: "",
            }}
            validationSchema={completeProfileSchema}
            onSubmit={(values) => {
              handleProfileSubmit(values);
            }}
          >
            {({ errors, touched, handleChange, values }) => (
              <Form>
                <div className="field">
                  <input
                    name="firstName"
                    className="nameInput"
                    disabled
                    type="text"
                    value={profileUpdate?.firstName}
                    onChange={handleProfileChange}
                    placeholder={t("GENERAL.FIRST_NAME")}
                  />
                </div>
                <div className="field">
                  <input
                    name="lastName"
                    disabled
                    className="nameInput"
                    type="text"
                    value={profileUpdate?.lastName}
                    onChange={handleProfileChange}
                    placeholder={t("GENERAL.LAST_NAME")}
                  />
                </div>
                <div className="field">
                  <input
                    name="email"
                    disabled
                    className="nameInput"
                    type="email"
                    value={profileUpdate?.email}
                    placeholder={t("GENERAL.EMAIL")}
                  />
                </div>
                <div className="createPassword field">
                  <label>{t("GENERAL.CREATE_NEW_PASSWORD")}</label>
                  <input
                    name="password"
                    className="nameInput"
                    type="password"
                    value={values?.password}
                    onChange={handleChange}
                    placeholder=""
                  />
                  {errors.password && touched.password ? (
                    <div className="errorMsgs">{errors.password}</div>
                  ) : null}
                </div>
                {/* <button type="submit">Submit</button> */}
                <button type="submit" className={"submitBtn"}>
                  {t("GENERAL.SUBMIT")}
                </button>
              </Form>
            )}
          </Formik>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
