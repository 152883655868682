import { AssessmentButton } from "ui-components";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import "./ConfirmPopup.scss";
import { useTranslation } from "react-i18next";
import CircleCrossIcon from "../../../../utils/Images/CircleCrossIcon";
export interface IExperienceLeftSideProps {
  showDelete: boolean;
  attempt: any;
  yesCallback: any;
  noCallback: any;
  isCrossBtn?: boolean;
  handleClose?: () => void;
}

const ConfirmPopup: React.FunctionComponent<IExperienceLeftSideProps> = ({
  showDelete,
  attempt,
  yesCallback,
  noCallback,
  isCrossBtn = false,
  handleClose = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <CustomModal
        show={showDelete}
        isHeaderVisible={false}
        isFooterVisible={false}
        className="confirmPopup"
      >
        {isCrossBtn && (
          <div onClick={handleClose} className="popupCrossIcon">
            <CircleCrossIcon />
          </div>
        )}
        <div className="deleteData">
          <div className="noListFound">
            <div className="text-center">
              <div className="popupLabel">
                {attempt < 5 && (
                  <h3>
                    {t("GENERAL.ALERT")}
                    <br />
                    {/* <OrdinalShift number={attempt} /> */}
                    {`${t("GENERAL.EXIT_MSG2")} ${5 - attempt} ${t("GENERAL.EXIT_MSG3")}`}
                  </h3>
                )}
                {attempt === 5 && <h3>{t("GENERAL.LAST_ATTEMPT")}</h3>}
              </div>
              <div className="deleteBtnSec">
                <AssessmentButton className="lightBtn" onClick={yesCallback}>
                  {t("GENERAL.YES")}
                </AssessmentButton>
                <AssessmentButton className="lightBtn" onClick={noCallback}>
                  {t("GENERAL.NO")}
                </AssessmentButton>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ConfirmPopup;
