import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AssessmentButton, InputField, showToast } from "ui-components";
import { loginUserData } from "../../../utils/Common/Constant";
import * as Yup from "yup";
import ApplicantSignInWrapper from "../../../components/ApplicantSignInWrapper/ApplicantSignInWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
import {
  resetPasswordApi,
  validateToken,
} from "../../../service/SubmitAssessment/SubmitAssessment";
import { useEffect, useState } from "react";
import Loader from "../../../components/Loader/Loader";
const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const [showPage, setShowPage] = useState(false);
  // login User Schema
  const loginUserSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t("ERROR.PASSWORD_MUST"))
      .max(32, t("ERROR.PASSWORD_MUST"))
      .matches(/[a-zA-Z]/, t("ERROR.PASSWORD_LETTER"))
      .matches(/[0-9]/, t("ERROR.PASSWORD_NUMBER"))
      .matches(
        /[!@#$%^&*(),.?":{}\\|<>`~\-_=+[\];:'"<>/?]/,
        t("ERROR.PASSWORD_SPECIAL"),
      )

      .trim()
      .required(t("ERROR.PASSWORD_REQUIRED")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        t("ERROR.CONFIRM_PASSWORD_MATCH"),
      )
      .trim()
      .required(t("ERROR.CONFIRM_PASSWORD_REQUIRED")),
  });
  useEffect(() => {
    if (token && type) {
      validateTokenApi();
    }
  }, [token, type]);
  const validateTokenApi = async () => {
    try {
      const res = await validateToken(token, type);
      if (res?.data?.status === 200) {
        setShowPage(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // reset password api call
  const resetPasswordApiCall = async (data: any, setSubmitting: any) => {
    try {
      const res = await resetPasswordApi(data);
      if (res?.status === 200) {
        showToast(res?.customMessage, "success");
        navigate(`/${currentLanguage}/applicant-login`, { replace: true });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting && setSubmitting(false);
    }
  };
  //call onsubmit password creation
  const handleConfirmPassword = (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    setSubmitting && setSubmitting(true);
    resetForm && resetForm();
    const data = {
      token: token,
      type: type,
      password: value?.password,
    };
    resetPasswordApiCall(data, setSubmitting);
  };
  return (
    <ApplicantSignInWrapper>
      {showPage ? (
        <Formik
          initialValues={loginUserData}
          validationSchema={loginUserSchema}
          onSubmit={(value, { resetForm, setSubmitting }) => {
            handleConfirmPassword(value, resetForm, setSubmitting);
          }}
        >
          {({ errors, touched, values, setFieldValue, isSubmitting }) => {
            return (
              <Form>
                <div className="userContent">
                  <h1 className="userHeadingApp">
                    {t("GENERAL.CREATE_NEW_PASS")}
                  </h1>
                  <div className="mt-5">
                    <Field name="password">
                      {() => (
                        <div className="passwordSec">
                          <InputField
                            placeholder={`${t("GENERAL.NEW_PASSWORD")} *`}
                            onChange={(newValue) =>
                              setFieldValue("password", newValue)
                            }
                            value={values.password}
                            additionalProps={{
                              className: "passwordApplicantLogin",
                            }}
                            type="password"
                          />
                        </div>
                      )}
                    </Field>
                    {errors?.password && touched?.password ? (
                      <div className="errorMessage">{errors?.password}</div>
                    ) : null}
                    <Field name="confirmPassword">
                      {() => (
                        <div className="passwordSec">
                          <InputField
                            placeholder={`${t("GENERAL.CONFIRM_PASSWORD")} *`}
                            onChange={(newValue) =>
                              setFieldValue("confirmPassword", newValue)
                            }
                            value={values.confirmPassword}
                            additionalProps={{
                              className: "passwordApplicantLogin",
                            }}
                            type="password"
                          />
                        </div>
                      )}
                    </Field>
                    {errors?.confirmPassword && touched?.confirmPassword ? (
                      <div className="errorMessage">
                        {errors?.confirmPassword}
                      </div>
                    ) : null}
                  </div>
                  <AssessmentButton
                    className="submitBtn"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? t("GENERAL.LOADING") : t("GENERAL.SAVE")}
                  </AssessmentButton>
                </div>
                <div className="password-instructions">
                  <div>
                    <li>{t("GENERAL.INSTRUCTION1")}</li>
                    <li>{t("GENERAL.INSTRUCTION2")}</li>
                    <li>{t("GENERAL.INSTRUCTION3")}</li>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </ApplicantSignInWrapper>
  );
};
export default ResetPassword;
