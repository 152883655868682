import "./ComingSoon.scss";
import { useEffect } from "react";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
import BackArrow from "../../utils/Images/BackArrow";
interface ComingSoonProps {
  title?: string;
  isBackArrowVisible?: boolean;
}
const ComingSoon = ({ title, isBackArrowVisible }: ComingSoonProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { t } = useTranslation();
  return (
    <div className="comingSoonScreen">
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="comingSoonMsg">
              <div className="pageTitle">
                {isBackArrowVisible && (
                  <div onClick={() => window.history.back()}>
                    <BackArrow />
                  </div>
                )}
                <h2>{title}</h2>
              </div>
              <div className="wipGif">
                <div className="progressImg">
                  <img
                    src={require("../../utils/Images/workProgress.gif")}
                    alt=""
                  />
                </div>
                <h2>{t("GENERAL.WORK_IN_PROGRESS")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-div">
        <Footer />
      </div>
    </div>
  );
};

export default ComingSoon;
