import React from "react";
import { AssessmentButton, Button } from "ui-components";
import CircleTick from "../../../utils/Images/CircleTick";
import CircleCrossTick from "../../../utils/Images/CircleCrossTick";
import MicroPhone from "../../../utils/Images/MicroPhone";
import CaptureImage from "../../../utils/Images/CaptureImage";
import InternetSpeed from "../../../utils/Images/InternetSpeed";
import { useTranslation } from "react-i18next";
import Camera from "../../../utils/Images/Camera";
import RefreshIcon from "../../../utils/Images/RefreshIcon";
interface CheckSystemCaptibility {
  permission?: any;
  text?: string;
  icon?: string;
  checkFn: () => void;
  isLoading?: boolean;
  retestFn?: any;
  internetSpeedCount?: null | number;
}
const iconComponent = (
  icon: string | undefined,
  permission: boolean,
  isLoading: boolean,
) => {
  switch (icon) {
    case "Microphone":
      if (permission !== null) {
        return (
          <MicroPhone color={permission ? "var(--primary-green)" : "#E41B1B"} />
        );
      } else {
        return <MicroPhone />;
      }
    case "Camera":
      if (permission !== null && !isLoading) {
        return (
          <Camera color={permission ? "var(--primary-green)" : "#E41B1B"} />
        );
      } else {
        return <Camera />;
      }
    case "InternetSpeed":
      if (permission !== null) {
        return (
          <InternetSpeed
            color={permission ? "var(--primary-green)" : "#E41B1B"}
          />
        );
      } else {
        return <InternetSpeed />;
      }
    case "CaptureImage":
      if (permission !== null) {
        return (
          <CaptureImage
            color={permission ? "var(--primary-green)" : "#E41B1B"}
          />
        );
      } else {
        return <CaptureImage />;
      }

    default:
      return null;
  }
};
const CheckSystemCaptibility: React.FC<CheckSystemCaptibility> = ({
  permission,
  text,
  icon,
  checkFn,
  isLoading = false,
  retestFn,
  internetSpeedCount,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        isLoading
          ? "testCheckCommon"
          : permission === null
            ? "testCheckCommon"
            : permission
              ? "testCheckCommon testCheck-1"
              : "testCheckCommon testCheck-2"
      }
    >
      <div className="iconText">
        <div className="systemIcon">
          {icon && iconComponent(icon, permission, isLoading)}
        </div>
        <div className="systemHeading">
          <p>
            {text}
            {text == t("SUBMIT_ASSESSMENT.INTERNET_SPEED_TEST") && (
              <span>
                {permission && internetSpeedCount ? (
                  <>{internetSpeedCount} (Mbps)</>
                ) : (
                  <></>
                )}
              </span>
            )}{" "}
          </p>
        </div>
      </div>
      <div className="checkSec">
        {permission === null ? (
          <>
            {isLoading ? (
              <>
                <Button
                  className="btn btn-primary permissionDisabled"
                  type="button"
                  disabled
                >
                  <span className="sr-only">{t("GENERAL.LOADING")}</span>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </Button>
              </>
            ) : (
              <AssessmentButton onClick={() => checkFn()}>
                {t("GENERAL.CHECK")}
              </AssessmentButton>
            )}
          </>
        ) : permission ? (
          <>
            {isLoading ? (
              <>
                <Button
                  className="btn btn-primary permissionDisabled"
                  type="button"
                  disabled
                >
                  <span className="sr-only">{t("GENERAL.LOADING")}</span>{" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </Button>
              </>
            ) : (
              <div className="groupBtnMid">
                <div>
                  <CircleTick />
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <>
                <Button
                  className="btn btn-primary permissionDisabled"
                  type="button"
                  disabled
                >
                  <span className="sr-only">{t("GENERAL.LOADING")}</span>{" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </Button>
              </>
            ) : (
              <div className="groupBtnMid">
                <div>
                  <CircleCrossTick />
                </div>
                <div className="cursorPointer" onClick={retestFn}>
                  <RefreshIcon />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CheckSystemCaptibility;
