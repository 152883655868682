import { useTranslation } from "react-i18next";
import "./Footer.scss";
import LogoTagline from "../../utils/Images/LogoTagline";
import packageJson from "../../../package.json";
import SupportReportPopup from "../SupportReportPopup/SupportReportPopup";
import { useState } from "react";
const Footer = () => {
  const { t } = useTranslation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePopupOpen = (text: any) => {
    setTitle(text);
    setIsPopupOpen(true);
  };
  return (
    <div className="footer">
      <div className="logo">
        <LogoTagline />
      </div>
      <div className="middle">
        <p>
          © 2024, Evluate Global,&nbsp;LLC&nbsp;| &nbsp;{t("GENERAL.VERSION")}:
          {packageJson.version}
        </p>
      </div>

      <div className="base">
        <button
          className="getSupport"
          onClick={() => {
            handlePopupOpen("support");
          }}
        >
          {t("GET_SUPPORT")}
        </button>
        <button className="reportBug" onClick={() => handlePopupOpen("report")}>
          {t("REPORT_BUG")}
        </button>
      </div>
      <SupportReportPopup
        show={isPopupOpen}
        title={title}
        setIsPopupOpen={setIsPopupOpen}
      />
    </div>
  );
};

export default Footer;
