import { useTranslation } from "react-i18next";
import CustomModal from "../../../components/CustomModal/CustomModal";
import "./InstructionModal.scss";
import { AssessmentButton, CheckboxRadio } from "ui-components";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
export interface IinstructionModal {
  instruction: any;
  show: boolean;
  handleClose: any;
  handleShow: any;
}
const InstructionModal: React.FunctionComponent<IinstructionModal> = ({
  instruction,
  show,
  handleClose,
  handleShow,
}) => {
  const { t } = useTranslation();
  const [checkTerms, setCheckTerms] = useState(false);
  return (
    <CustomModal
      show={show}
      handleClose={handleClose}
      handleShow={handleShow}
      instructionHeadTitle={t("GENERAL.INSTRUCTIONS")}
      isFooterVisible={false}
    >
      <div className="instructionModalContent">
        {instruction !== "undefined" && (
          <p
            className="assessmentDetailsInstruction"
            dangerouslySetInnerHTML={{
              __html: `${instruction}`,
            }}
          />
        )}
        <Modal.Footer>
          <div className="modalFooter">
            <div className="terms_Condition_check">
              <CheckboxRadio
                type={"checkbox"}
                checked={checkTerms}
                onChange={() => setCheckTerms(!checkTerms)}
              />
              <span>{t("GENERAL.ACCEPT_PROCEED")}</span>
            </div>
            <div className="closeButton">
              <AssessmentButton onClick={handleClose} disabled={!checkTerms}>
                {t("GENERAL.ACCEPT")}
              </AssessmentButton>
            </div>
          </div>
        </Modal.Footer>
      </div>
    </CustomModal>
  );
};

export default InstructionModal;
